document.addEventListener("DOMContentLoaded", afterDOMContentLoaded);

export let allowWindowScroll = true;
let lastWindowScrollBeforeDisabled = 0;

function afterDOMContentLoaded() {
  document
    .querySelectorAll(".discord-btn")
    .forEach((el) => el.addEventListener("click", onDiscordClick));

  document
    .getElementById("mobile-header-discord-btn")
    .addEventListener("click", onDiscordClick);

  const headerEl = document.getElementById("page-header");
  onPageScroll(headerEl);
  window.addEventListener("scroll", () => onPageScroll(headerEl), {
    passive: true,
  });

  const menuBtn = document.getElementById("hamburger-menu");
  const contentCont = document.getElementById("main-content-cont");
  const menuCont = document.getElementById("main-menu");
  menuBtn.addEventListener("click", () =>
    onMenuBtnClick(menuBtn, contentCont, menuCont)
  );
  window.addEventListener("scroll", windowScrollBlockHandler);
}

function onPageScroll(headerEl: HTMLElement) {
  if (window.scrollY > 100) {
    headerEl.classList.add("page-scrolled");
  } else {
    headerEl.classList.remove("page-scrolled");
  }
}

function onDiscordClick() {
  gtag("event", "discord-click");
  gtag("event", "conversion", {
    send_to: "AW-10861975328/e0yGCIXnmKYDEKCusrso",
  });
  fbq("track", "Lead");
}

function onMenuBtnClick(
  menuBtn: HTMLElement,
  contentCont: HTMLElement,
  menuCont: HTMLElement
) {
  if (menuBtn.classList.contains("open-menu")) {
    closeMenu(menuBtn, contentCont, menuCont);
  } else {
    openMenu(menuBtn, contentCont, menuCont);
  }
}

export function closeMenu(
  menuBtn: HTMLElement,
  contentCont: HTMLElement,
  menuCont: HTMLElement
) {
  menuBtn.classList.remove("open-menu");
  contentCont.classList.remove("concealed");
  menuCont.classList.remove("visible");
  allowWindowScroll = true;
}

function openMenu(
  menuBtn: HTMLElement,
  contentCont: HTMLElement,
  menuCont: HTMLElement
) {
  menuBtn.classList.add("open-menu");
  contentCont.classList.add("concealed");
  menuCont.classList.add("visible");
  allowWindowScroll = false;
  lastWindowScrollBeforeDisabled = window.scrollY;
}

function windowScrollBlockHandler() {
  if (!allowWindowScroll) {
    window.scrollTo(0, lastWindowScrollBeforeDisabled);
  }
}
